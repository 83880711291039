var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"variant":_vm.skin,"show":_vm.isloading,"spinner-variant":"primary","spinner-type":"grow"}},[_c('b-row',[_c('b-col',[_c('h4',[_vm._v(" "+_vm._s(_vm.isTransmission ? "Gravações Futura Transmissão" : "Gravações Gerais")+" ")])]),_c('b-col',[_c('div',{staticClass:"d-flex flex-row-reverse mb-1"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left.html",value:(
              _vm.viewMode
                ? '<b>VISUALIZAÇÃO ATUAL</b><br/>Exibindo apenas os processos prontos para validação'
                : '<b>VISUALIZAÇÃO ATUAL</b><br/>Exibindo todos os processos'
            ),expression:"\n              viewMode\n                ? '<b>VISUALIZAÇÃO ATUAL</b><br/>Exibindo apenas os processos prontos para validação'\n                : '<b>VISUALIZAÇÃO ATUAL</b><br/>Exibindo todos os processos'\n            ",modifiers:{"hover":true,"left":true,"html":true}}],staticClass:"mr-0",attrs:{"variant":_vm.viewMode ? 'outline-info' : 'info',"size":"sm"},on:{"click":function () {
                _vm.viewMode = !_vm.viewMode;
                _vm.filter();
              }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.viewMode ? "Visualizar Todos" : "Apenas Prontos p/ Validação"))])],1)],1)])],1),_c('Table',{staticClass:"text-nowrap mb-0",staticStyle:{"font-size":"10px"},attrs:{"fields":_vm.fields,"list":_vm.list,"border":"full","responsive":""},on:{"orderBy":_vm.getRecordsOrderBy},scopedSlots:_vm._u([{key:"id",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"font-size":"12px"},attrs:{"to":("/audiovisual/recording/view/" + (data.item.id)),"variant":"secondary"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"LinkIcon"}}),_c('span',[_vm._v(_vm._s(data.item.id))])],1)],1)]}},{key:"company",fn:function(data){return [(data.item.company.length > 0)?_c('div',_vm._l((data.item.company),function(company,index){return _c('div',{key:index,staticClass:"text-center",class:[
              data.item.company.length > 1 &&
              index != data.item.company.length - 1
                ? 'mb-25'
                : 'm-0' ]},[_c('b-badge',{staticClass:"d-block",attrs:{"variant":_vm.skin == 'dark' ? 'light-info' : 'light-dark'}},[_vm._v(" "+_vm._s(company .split(" ") .filter(function (f) { return f != "Estratégia"; }) .join(" "))+" ")])],1)}),0):_c('div',[_vm._v("--")])]}},{key:"teacher_name",fn:function(data){return [_c('div',{staticClass:"text-truncate ellipsis",staticStyle:{"max-width":"100px"},attrs:{"data-text":data.item.teacher_name || '--'}},[_vm._v(" "+_vm._s(data.item.teacher_name || "--")+" ")])]}},{key:"project_name",fn:function(data){return [_c('div',{staticClass:"text-truncate ellipsis",staticStyle:{"max-width":"100px"},attrs:{"data-text":data.item.project_name || '--'}},[_vm._v(" "+_vm._s(data.item.project_name || "--")+" ")])]}},{key:"event_name",fn:function(data){return [_c('div',{staticClass:"text-truncate ellipsis",staticStyle:{"max-width":"100px"},attrs:{"data-text":data.item.event_name || '--'}},[_vm._v(" "+_vm._s(data.item.event_name || "--")+" ")])]}},{key:"products",fn:function(data){return [(data.item.products.length > 0)?_c('div',_vm._l((data.item.products),function(prod,index){return _c('div',{key:index,staticClass:"text-center",class:[
              data.item.products.length > 1 &&
              index != data.item.products.length - 1
                ? 'mb-25'
                : 'm-0' ]},[_c('b-badge',{staticClass:"d-block",attrs:{"variant":_vm.skin == 'dark' ? 'light-info' : 'light-dark'}},[_vm._v(" "+_vm._s(prod)+" ")])],1)}),0):_c('div',[_vm._v("--")])]}},{key:"priority",fn:function(data){return [_c('div',{staticClass:"text-center"},[(data.item.prioritySelected.value >= 0)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.prioritySelected.label),expression:"data.item.prioritySelected.label",modifiers:{"hover":true,"top":true}}],staticClass:"px-1",attrs:{"variant":data.item.prioritySelected.color}},[_c('feather-icon',{attrs:{"icon":data.item.prioritySelected.icon}})],1):_vm._e()],1)]}},{key:"uploaded_at",fn:function(data){return [(data.item.uploaded_at)?_c('div',[_vm._v(" "+_vm._s(_vm.statusDateFormatted(data.item.uploaded_at))+" ")]):_c('div',[_vm._v("--")])]}},{key:"process",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('Tracking',{attrs:{"id":{ recording_media_id: data.item.id },"validateAction":"","size":"30px"},on:{"actionClick":function (payload) { return _vm.validate(data.item, payload); }}})],1)]}}])}),(_vm.more)?_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('b-button',{attrs:{"variant":"secondary","pill":""},on:{"click":_vm.getLoadMore}},[_vm._v(" Carregar Mais ")])],1):_vm._e()],1),_c('b-button',{staticClass:"btn-filter-appoitment btn-icon rounded-circle",attrs:{"variant":_vm.containsFilter ? 'gradient-warning' : 'gradient-info'},on:{"click":function($event){_vm.isActiveFilter = true}}},[_c('feather-icon',{attrs:{"icon":"FilterIcon","size":"25"}})],1),_c('b-sidebar',{attrs:{"sidebar-class":"sidebar-lg","bg-variant":"white","right":"","backdrop":"","shadow":"","no-header":""},scopedSlots:_vm._u([(_vm.isActiveFilter)?{key:"default",fn:function(ref){
            var hide = ref.hide;
return [_c('div',{staticClass:"\n          d-flex\n          justify-content-between\n          align-items-center\n          content-sidebar-header\n          px-2\n          py-1\n        "},[_c('h5',{staticClass:"mb-0"},[_vm._v("Filtros")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('b-form',{staticClass:"px-1 mt-1 mb-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ID da Gravação"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.record_id),callback:function ($$v) {_vm.record_id=$$v},expression:"record_id"}})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Empresa"}},[_c('v-select',{attrs:{"options":_vm.companies,"clearable":true,"autocomplete":"off","multiple":"","loading":_vm.companiesLoading},on:{"search":function (res) {
                    _vm.searchCompany(res);
                  }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" Pesquisando... "),_c('em',[_vm._v(_vm._s(search))])]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Digite o Nome")])]}}],null,true),model:{value:(_vm.companySelected),callback:function ($$v) {_vm.companySelected=$$v},expression:"companySelected"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Professor"}},[_c('v-select',{attrs:{"options":_vm.teachers,"clearable":true,"autocomplete":"off","loading":_vm.teacherLoading},on:{"search":function (res) {
                    _vm.searchTeacher(res);
                  }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" Pesquisando... "),_c('em',[_vm._v(_vm._s(search))])]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Digite o Nome")])]}}],null,true),model:{value:(_vm.teacherSelected),callback:function ($$v) {_vm.teacherSelected=$$v},expression:"teacherSelected"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Projeto"}},[_c('v-select',{attrs:{"options":_vm.projects,"clearable":true,"autocomplete":"off","loading":_vm.projectLoading},on:{"search":function (res) {
                    _vm.searchProject(res);
                  }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" Pesquisando... "),_c('em',[_vm._v(_vm._s(search))])]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Digite o Nome")])]}}],null,true),model:{value:(_vm.projectSelected),callback:function ($$v) {_vm.projectSelected=$$v},expression:"projectSelected"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Evento"}},[_c('v-select',{attrs:{"options":_vm.events,"clearable":true,"autocomplete":"off","loading":_vm.eventLoading},on:{"search":function (res) {
                    _vm.searchEvent(res);
                  }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" Pesquisando... "),_c('em',[_vm._v(_vm._s(search))])]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Digite o Nome")])]}}],null,true),model:{value:(_vm.eventSelected),callback:function ($$v) {_vm.eventSelected=$$v},expression:"eventSelected"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Aula"}},[_c('v-select',{attrs:{"options":_vm.courses,"clearable":true,"autocomplete":"off","loading":_vm.courseLoading},on:{"search":function (res) {
                    _vm.searchCourse(res);
                  }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" Pesquisando... "),_c('em',[_vm._v(_vm._s(search))])]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Digite o Nome")])]}}],null,true),model:{value:(_vm.courseSelected),callback:function ($$v) {_vm.courseSelected=$$v},expression:"courseSelected"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Produto"}},[_c('v-select',{attrs:{"options":_vm.products,"clearable":true,"autocomplete":"false","loading":_vm.productLoading,"multiple":""},model:{value:(_vm.productSelected),callback:function ($$v) {_vm.productSelected=$$v},expression:"productSelected"}})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_vm._v("Período de Gravação")]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-1","date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"placeholder":"Data Inicial","locale":"pt-br"},model:{value:(_vm.start_date_recording),callback:function ($$v) {_vm.start_date_recording=$$v},expression:"start_date_recording"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-2","date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"placeholder":"Data Final","locale":"pt-br"},model:{value:(_vm.end_date_recording),callback:function ($$v) {_vm.end_date_recording=$$v},expression:"end_date_recording"}})],1),_c('b-col',{staticClass:"mb-50 mt-1",attrs:{"cols":"12"}},[_vm._v("Período de Upload")]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-3","date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"placeholder":"Data Inicial","locale":"pt-br"},model:{value:(_vm.start_date_upload),callback:function ($$v) {_vm.start_date_upload=$$v},expression:"start_date_upload"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-4","date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"placeholder":"Data Final","locale":"pt-br"},model:{value:(_vm.end_date_upload),callback:function ($$v) {_vm.end_date_upload=$$v},expression:"end_date_upload"}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Prioridade"}},[_c('v-select',{attrs:{"options":_vm.priorities,"autocomplete":"off","clearable":true},model:{value:(_vm.prioritySelected),callback:function ($$v) {_vm.prioritySelected=$$v},expression:"prioritySelected"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Status de Edição"}},[_c('v-select',{attrs:{"options":_vm.editionStatus,"clearable":true,"autocomplete":"off","loading":_vm.statusLoading},on:{"search":function (res) {
                    _vm.searchStatus(res);
                  }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
                  var searching = ref.searching;
return [(searching)?[_vm._v(" Pesquisando... "),_c('em',[_vm._v(_vm._s(search))])]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Digite o Nome")])]}}],null,true),model:{value:(_vm.editionStatusSelected),callback:function ($$v) {_vm.editionStatusSelected=$$v},expression:"editionStatusSelected"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Status de Validação"}},[_c('v-select',{attrs:{"options":[
                  { label: 'Pendente', value: false },
                  { label: 'Finalizado', value: true } ],"autocomplete":"off","clearable":true},model:{value:(_vm.validateStatusSelected),callback:function ($$v) {_vm.validateStatusSelected=$$v},expression:"validateStatusSelected"}})],1)],1),_c('b-col',{attrs:{"cols":"9"}},[_c('b-button',{attrs:{"variant":"gradient-info","block":""},on:{"click":function($event){return _vm.filter(false)}}},[_vm._v(" FILTRAR ")])],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-button',{attrs:{"variant":"gradient-primary","block":""},on:{"click":_vm.clearfilter}},[_vm._v(" Limpar ")])],1)],1)],1)]}}:null],null,true),model:{value:(_vm.isActiveFilter),callback:function ($$v) {_vm.isActiveFilter=$$v},expression:"isActiveFilter"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }