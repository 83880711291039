import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  showall:() => {
    return _http.get(`/api/audio-visual/edition-job-status`)
  },
  autoComplete: (search) => {
    return _http.get(`/api/audio-visual/edition-job-status?page=${0}&search=${search}&asc`)
  },
  showPriorities: () => {
    return _http.get('/api/audio-visual/edition-job/all-priorities')
  },
  updatePriority: (payload) => {
    return _http.put('/api/audio-visual/edition-job', payload)
  },
  insertEditor: (payload) => {
    return _http.post('/api/audio-visual/edition-job', payload)
  },
  reverseCancellation: (payload) => {
    return _http.post('/api/audio-visual/edition-job/reverse-cancellation', payload)
  }

}