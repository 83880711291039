<template>
  <b-card>
    <b-overlay
      :variant="skin"
      :show="isloading"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-row>
        <b-col>
          <h4>
            {{
              isTransmission
                ? "Gravações Futura Transmissão"
                : "Gravações Gerais"
            }}
          </h4>
        </b-col>
        <b-col>
          <div class="d-flex flex-row-reverse mb-1">
            <b-button
              :variant="viewMode ? 'outline-info' : 'info'"
              size="sm"
              class="mr-0"
              @click="
                () => {
                  viewMode = !viewMode;
                  filter();
                }
              "
              v-b-tooltip.hover.left.html="
                viewMode
                  ? '<b>VISUALIZAÇÃO ATUAL</b><br/>Exibindo apenas os processos prontos para validação'
                  : '<b>VISUALIZAÇÃO ATUAL</b><br/>Exibindo todos os processos'
              "
            >
              <feather-icon icon="EyeIcon" class="mr-50" />
              <span class="align-middle">{{
                viewMode ? "Visualizar Todos" : "Apenas Prontos p/ Validação"
              }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <Table
        :fields="fields"
        :list="list"
        @orderBy="getRecordsOrderBy"
        border="full"
        style="font-size: 10px"
        class="text-nowrap mb-0"
        responsive
      >
        <template #id="data">
          <div class="text-center">
            <b-badge
              :to="`/audiovisual/recording/view/${data.item.id}`"
              variant="secondary"
              style="font-size: 12px"
            >
              <feather-icon icon="LinkIcon" class="mr-25" />
              <span>{{ data.item.id }}</span>
            </b-badge>
          </div>
        </template>

        <template #company="data">
          <div v-if="data.item.company.length > 0">
            <div
              v-for="(company, index) in data.item.company"
              :key="index"
              class="text-center"
              :class="[
                data.item.company.length > 1 &&
                index != data.item.company.length - 1
                  ? 'mb-25'
                  : 'm-0',
              ]"
            >
              <b-badge
                class="d-block"
                :variant="skin == 'dark' ? 'light-info' : 'light-dark'"
              >
                {{
                  company
                    .split(" ")
                    .filter((f) => f != "Estratégia")
                    .join(" ")
                }}
              </b-badge>
            </div>
          </div>
          <div v-else>--</div>
        </template>

        <template #teacher_name="data">
          <div
            :data-text="data.item.teacher_name || '--'"
            class="text-truncate ellipsis"
            style="max-width: 100px"
          >
            {{ data.item.teacher_name || "--" }}
          </div>
        </template>

        <template #project_name="data">
          <div
            :data-text="data.item.project_name || '--'"
            class="text-truncate ellipsis"
            style="max-width: 100px"
          >
            {{ data.item.project_name || "--" }}
          </div>
        </template>

        <template #event_name="data">
          <div
            :data-text="data.item.event_name || '--'"
            style="max-width: 100px"
            class="text-truncate ellipsis"
          >
            {{ data.item.event_name || "--" }}
          </div>
        </template>

        <template #products="data">
          <div v-if="data.item.products.length > 0">
            <div
              v-for="(prod, index) in data.item.products"
              :key="index"
              class="text-center"
              :class="[
                data.item.products.length > 1 &&
                index != data.item.products.length - 1
                  ? 'mb-25'
                  : 'm-0',
              ]"
            >
              <b-badge
                class="d-block"
                :variant="skin == 'dark' ? 'light-info' : 'light-dark'"
              >
                {{ prod }}
              </b-badge>
            </div>
          </div>
          <div v-else>--</div>
        </template>

        <template #priority="data">
          <div class="text-center">
            <b-badge
              v-if="data.item.prioritySelected.value >= 0"
              :variant="data.item.prioritySelected.color"
              v-b-tooltip.hover.top="data.item.prioritySelected.label"
              class="px-1"
            >
              <feather-icon :icon="data.item.prioritySelected.icon" />
            </b-badge>
          </div>
        </template>

        <template #uploaded_at="data">
          <div v-if="data.item.uploaded_at">
            {{ statusDateFormatted(data.item.uploaded_at) }}
          </div>
          <div v-else>--</div>
        </template>

        <template #process="data">
          <div class="text-center">
            <Tracking
              :id="{ recording_media_id: data.item.id }"
              @actionClick="(payload) => validate(data.item, payload)"
              validateAction
              size="30px"
            />
          </div>
        </template>
      </Table>

      <div v-if="more" class="d-flex justify-content-center mt-2">
        <b-button @click="getLoadMore" variant="secondary" pill>
          Carregar Mais
        </b-button>
      </div>
    </b-overlay>

    <!-- Filter -->
    <b-button
      class="btn-filter-appoitment btn-icon rounded-circle"
      :variant="containsFilter ? 'gradient-warning' : 'gradient-info'"
      @click="isActiveFilter = true"
    >
      <feather-icon icon="FilterIcon" size="25" />
    </b-button>
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isActiveFilter"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isActiveFilter">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Filtros</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-form class="px-1 mt-1 mb-1">
          <b-row>
            <b-col cols="12">
              <b-form-group label="ID da Gravação">
                <b-form-input type="number" v-model="record_id" />
              </b-form-group>
            </b-col>

            <b-col cols="12" v-show="true">
              <b-form-group label="Empresa">
                <v-select
                  v-model="companySelected"
                  :options="companies"
                  :clearable="true"
                  autocomplete="off"
                  multiple
                  @search="
                    (res) => {
                      searchCompany(res);
                    }
                  "
                  :loading="companiesLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Professor">
                <v-select
                  v-model="teacherSelected"
                  :options="teachers"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchTeacher(res);
                    }
                  "
                  :loading="teacherLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Projeto">
                <v-select
                  v-model="projectSelected"
                  :options="projects"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchProject(res);
                    }
                  "
                  :loading="projectLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Evento">
                <v-select
                  v-model="eventSelected"
                  :options="events"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchEvent(res);
                    }
                  "
                  :loading="eventLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Aula">
                <v-select
                  v-model="courseSelected"
                  :options="courses"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchCourse(res);
                    }
                  "
                  :loading="courseLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Produto">
                <v-select
                  v-model="productSelected"
                  :options="products"
                  :clearable="true"
                  autocomplete="false"
                  :loading="productLoading"
                  multiple
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col class="mb-50" cols="12">Período de Gravação</b-col>
            <b-col cols="6">
              <b-form-datepicker
                id="datepicker-1"
                v-model="start_date_recording"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                placeholder="Data Inicial"
                locale="pt-br"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                id="datepicker-2"
                v-model="end_date_recording"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                placeholder="Data Final"
                locale="pt-br"
              />
            </b-col>

            <b-col class="mb-50 mt-1" cols="12">Período de Upload</b-col>
            <b-col cols="6">
              <b-form-datepicker
                id="datepicker-3"
                v-model="start_date_upload"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                placeholder="Data Inicial"
                locale="pt-br"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                id="datepicker-4"
                v-model="end_date_upload"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                placeholder="Data Final"
                locale="pt-br"
              />
            </b-col>

            <b-col cols="12" class="mt-1">
              <b-form-group label="Prioridade">
                <v-select
                  v-model="prioritySelected"
                  :options="priorities"
                  autocomplete="off"
                  :clearable="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Status de Edição">
                <v-select
                  v-model="editionStatusSelected"
                  :options="editionStatus"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchStatus(res);
                    }
                  "
                  :loading="statusLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <!--
            <b-col cols="12">
              <b-form-group label="Status de Upload">
                <v-select
                  v-model="uploadStatusSelected"
                  :options="[
                    { label: 'Pendente', value: false },
                    { label: 'Finalizado', value: true },
                  ]"
                  autocomplete="off"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            -->

            <b-col cols="12" class="mb-1">
              <b-form-group label="Status de Validação">
                <v-select
                  v-model="validateStatusSelected"
                  :options="[
                    { label: 'Pendente', value: false },
                    { label: 'Finalizado', value: true },
                  ]"
                  autocomplete="off"
                  :clearable="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="9">
              <b-button @click="filter(false)" variant="gradient-info" block>
                FILTRAR
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button @click="clearfilter" variant="gradient-primary" block>
                Limpar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import Table from "@/components/Table.vue";
import Tracking from "@/components/Tracking.vue";
import { mapMutations, mapState } from "vuex";
import {
  VBToggle,
  VBTooltip,
  BBadge,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormTags,
  BFormDatepicker,
} from "bootstrap-vue";
import _recordMediaService from "@/services/audiovisual/recording-media";
import _editorsService from "@/services/users-service";
import _prioritiesService from "@/services/audiovisual/edition-job-service";
import _teacherService from "@/services/teachers-service";
import _eventService from "@/services/event-service";
import _uploadService from "@/services/upload-service";
import _productService from "@/services/audiovisual/products-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    Table,
    BBadge,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormTags,
    BFormDatepicker,
    Tracking,
  },
  props: {
    projectId: {
      type: Object,
      default: null,
    },
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapState({
      isTransmission: (state) => state.toolbar.validateUpload.is_transmission,
    }),
  },
  data() {
    return {
      viewMode: true,
      start_date_recording: null,
      end_date_recording: null,
      start_date_upload: null,
      end_date_upload: null,
      containsFilter: false,
      record_id: null,
      prioritySelected: null,
      teacherSelected: null,
      teachers: [],
      teacherLoading: false,
      projectSelected: null,
      projects: [],
      projectLoading: false,
      eventSelected: null,
      events: [],
      eventLoading: false,
      editionStatusSelected: null,
      uploadStatusSelected: null,
      validateStatusSelected: { label: "Pendente", value: false },
      editionStatus: [],
      statusLoading: false,
      courseSelected: null,
      courses: [],
      courseLoading: false,
      productSelected: null,
      products: [],
      productLoading: false,
      companySelected: null,
      companies: [],
      companiesLoading: false,
      icons: ["ChevronsUpIcon", "ChevronUpIcon", "ChevronDownIcon"],
      isActiveFilter: false,
      more: false,
      isloading: false,
      currentePage: 1,
      search: "",
      size: 0,
      fields: [
        { key: "id", label: "ID", orderBy: { type: "back" } },
        { key: "company", label: "Empresa" },
        { key: "teacher_name", label: "Professor", orderBy: { type: "back" } },
        { key: "project_name", label: "Projeto", orderBy: { type: "back" } },
        { key: "event_name", label: "Evento/Aula", orderBy: { type: "back" } },
        { key: "products", label: "Produto" },
        {
          key: "priority",
          label: "Prio.",
          tooltip: "Prioridade",
          orderBy: { type: "back" },
        },
        { key: "process", label: "Processo" },
      ],
      list: [],
      priorities: [],
      autoReload: null,
      ascOrDes: false,
      orderByKey: "",
    };
  },
  created() {
    this.getPriorities();
    this.getProducts();
    this.resetCurrentSize();
    this.UPDATE_CURRENT_TOOLBAR("validate-upload");
  },
  mounted() {
    this.$root.$on("changeTransmissionView", () => {
      this.filter();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.autoReload) {
      clearInterval(this.autoReload);
      this.UPDATE_CURRENT_TOOLBAR("none");
      next();
    } else {
      next();
    }
  },
  methods: {
    ...mapMutations({
      UPDATE_CURRENT_TOOLBAR: "toolbar/UPDATE_CURRENT_TOOLBAR",
    }),
    resetCurrentSize() {
      this.size = this.$utils.paginationSize();
    },
    validate(item, payload) {
      let isDualOpt = payload.mainProduct && payload.ftProduct;
      let options = {
        mainProduct: { disabled: true, checked: false },
        ftProduct: { disabled: true, checked: false },
      };
      if (payload.mainProduct) {
        options.mainProduct.disabled = false;
        options.mainProduct.checked = true;
      }
      if (payload.ftProduct) {
        options.ftProduct.disabled = false;
        options.ftProduct.checked = !isDualOpt;
      }
      this.$swal({
        title: "Selecione qual fluxo será validado:",
        html: `
          <div class="mt-50 mb-1">
            <input type="radio" name="productselect" id="main-radio" value="${
              payload.uploadControlID
            }" ${options.mainProduct.disabled ? "disabled" : ""} ${
            options.mainProduct.checked ? "checked" : ""
          } /> <label class="mr-1" for="main-radio"> Principal </label>
            <input type="radio" name="productselect" id="ft-radio" value="${
              payload.uploadControlTransmissionID
            }" ${options.ftProduct.disabled ? "disabled" : ""} ${
            options.ftProduct.checked ? "checked" : ""
          } /> <label for="ft-radio"> Futura Transmissão </label>
        </div>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Validar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let radios = document.getElementsByName("productselect");
          let id = 0;
          radios.forEach(f => {
            if (f.checked) {
              id = f.value;
            }
          })
          _uploadService
            .validateUpload(id)
            .then((res) => {
              item.is_validated = res.response.validated;
              this.$utils.toast("Notificação", "Validado com sucesso.");
              this.filter();
            })
            .catch((error) => this.$utils.toastError("Notificação", error));
        } else {
          item.is_validated = false;
        }
      });
    },
    getPriorities() {
      this.isloading = true;
      _prioritiesService
        .showPriorities()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.priorities = this.$utils.populardropdown(
              res.content,
              "name",
              "value",
              false,
              false,
              false
            );
            this.getEditors();
          } else {
            this.$utils.toastError(
              "Notificação",
              "Nenhum valor de prioridade encontrado."
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getEditors() {
      _editorsService
        .userTypeEditors()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.editors = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
            let params = this.getParams();
            this.getRecords(this.currentePage, params);
          } else {
            this.$utils.toastError("Notificação", "Nenhum editor encontrado.");
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getRecords(_page, params = null, isAutoLoad = false) {
      this.containsFilter = params ? true : false;
      if (!isAutoLoad) this.isloading = true;
      this.startAutoReload();
      _recordMediaService
        .showRecordingEditor(
          _page,
          isAutoLoad ? this.size : this.$utils.paginationSize(),
          params
        )
        .then((res) => {
          if (res.content) {
            res.content.forEach((element) => {
              element.time = "00:00hs";
              element.timeBegan = null;
              element.started = null;
              element.running = false;
              element.products = [];
              element.company = [];

              if (element.recordingMediaFilesRS) {
                element.recordingMediaFilesRS.forEach((medias) => {
                  medias.recordingMediaSubjectRS.forEach((subject) => {
                    if (
                      !element.products.some((p) => {
                        return p == subject.products;
                      })
                    ) {
                      element.products.push(subject.products);
                    }
                  });
                });
              }

              if (element.recordingMediaFilesRS) {
                element.recordingMediaFilesRS.forEach((medias) => {
                  medias.recordingMediaSubjectRS.forEach((subject) => {
                    if (
                      !element.company.some((p) => {
                        return p == subject.company;
                      })
                    ) {
                      element.company.push(subject.company);
                    }
                  });
                });
              }

              if (element.priority_name && element.priority != null) {
                element.prioritySelected = {
                  label: element.priority_name,
                  value: element.priority,
                };
                switch (element.priority) {
                  case 0:
                    element.prioritySelected.icon = "ChevronsUpIcon";
                    element.prioritySelected.color = "danger";
                    break;

                  case 1:
                    element.prioritySelected.icon = "ChevronUpIcon";
                    element.prioritySelected.color = "warning";
                    break;

                  case 2:
                    element.prioritySelected.icon = "ChevronDownIcon";
                    element.prioritySelected.color = "info";
                    break;
                }
              } else {
                element.prioritySelected = {
                  label: "",
                  value: -1,
                  icon: "",
                  color: "",
                };
              }
            });

            if (isAutoLoad) {
              this.list = res.content;
            } else {
              this.more = res.content.length >= this.$utils.paginationSize();
              this.list.push(...res.content);
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    startAutoReload() {
      if (this.autoReload) {
        clearInterval(this.autoReload);
      }
      this.autoReload = setInterval(() => {
        let params = this.getParams();
        this.getRecords(1, params, true);
      }, 10000);
    },
    getLoadMore(_page) {
      this.size += this.$utils.paginationSize();
      let params = this.getParams();
      this.currentePage += 1;
      this.getRecords(this.currentePage, params);
    },
    getParams() {
      let params = {
        is_transmission: this.isTransmission,
        id: this.record_id ? this.record_id : null,
        teacher_id:
          this.teacherSelected != null ? this.teacherSelected.value : null,
        project_id:
          this.projectSelected != null ? this.projectSelected.value : null,
        product_id: this.productSelected
          ? this.productSelected.map((m) => m.value)
          : null,
        event_id: this.eventSelected != null ? this.eventSelected.value : null,
        course_id:
          this.courseSelected != null ? this.courseSelected.value : null,
        edition_status_id:
          this.editionStatusSelected != null
            ? this.editionStatusSelected.value
            : null,
        upload_status: this.viewMode,
        validate_status_id:
          this.validateStatusSelected != null
            ? this.validateStatusSelected.value
            : null,
        company_id: this.companySelected
          ? this.companySelected.map((m) => m.value)
          : null,
        priority:
          this.prioritySelected != null ? this.prioritySelected.value : null,
        start_date_recording: this.start_date_recording,
        end_date_recording: this.end_date_recording,
        start_date_upload: this.start_date_upload,
        end_date_upload: this.end_date_upload,
        orderByKey: this.orderByKey,
        ascOrDes: this.ascOrDes,
      };

      return params;
    },
    getRecordsOrderBy(_params) {
      this.ascOrDes = _params.ascOrDes;
      this.orderByKey = _params.orderByKey;
      this.filter();
    },
    filter(alertByPass = true) {
      this.resetCurrentSize();
      if (this.viewMode && !alertByPass) {
        this.$swal({
          title: "Visualização",
          text: "O modo de visualização atual está restrito aos processos prontos para validação. Deseja aplicar a filtragem a está visualização?",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Sim, continuar!",
          cancelButtonText: "Visualizar todos e continuar!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-info ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (!result.value) {
            this.viewMode = !this.viewMode;
            this.currentePage = 1;
            this.list = [];
            let params = this.getParams();
            this.getRecords(this.currentePage, params);
            this.isActiveFilter = false;
          }
        });
      } else {
        this.currentePage = 1;
        this.list = [];
        let params = this.getParams();
        this.getRecords(this.currentePage, params);
        this.isActiveFilter = false;
      }
    },
    clearfilter() {
      this.record_id = null;
      this.prioritySelected = null;
      this.teacherSelected = null;
      this.projectSelected = null;
      this.eventSelected = null;
      this.courseSelected = null;
      this.companySelected = [];
      this.productSelected = [];
      this.editionStatusSelected = null;
      this.uploadStatusSelected = null;
      this.validateStatusSelected = null;
      this.start_date_recording = null;
      this.end_date_recording = null;
      this.start_date_upload = null;
      this.end_date_upload = null;
      this.filter();
    },
    statusDateFormatted(date) {
      if (date) {
        let dateSplited = date.split("T");
        let dateFormatted = dateSplited[0].split("-").reverse().join("/");
        return dateFormatted + " - " + dateSplited[1].substr(0, 8);
      } else {
        return "--";
      }
    },
    searchTeacher(_search) {
      if (_search && _search.length >= 3) {
        if (!this.teacherLoading) {
          this.teacherLoading = true;
          setTimeout(() => {
            _teacherService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.teachers = [];
                  res.content.forEach((element) => {
                    this.teachers.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.teacherLoading = false));
          }, 1500);
        }
      }
    },
    searchProject(_search) {
      if (_search && _search.length >= 3) {
        if (!this.projectLoading) {
          this.projectLoading = true;
          setTimeout(() => {
            _recordMediaService
              .autoCompleteEditionProject(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.projects = [];
                  res.content.forEach((element) => {
                    if (
                      this.projects.some((s) => s.value == element.project_id)
                    )
                      return;
                    this.projects.push({
                      value: element.project_id,
                      label: element.project_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.projectLoading = false));
          }, 1500);
        }
      }
    },
    searchEvent(_search) {
      if (_search && _search.length >= 3) {
        if (!this.eventLoading) {
          this.eventLoading = true;
          setTimeout(() => {
            _recordMediaService
              .autoCompleteEditionEvent(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.events = [];
                  res.content.forEach((element) => {
                    this.events.push({
                      value: element.event_id,
                      label: element.event_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.eventLoading = false));
          }, 1500);
        }
      }
    },
    searchCourse(_search) {
      if (_search && _search.length >= 3) {
        if (!this.courseLoading) {
          this.courseLoading = true;
          setTimeout(() => {
            _recordMediaService
              .autoCompleteEditionCourse(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.courses = [];
                  res.content.forEach((element) => {
                    this.courses.push({
                      value: element.course_id,
                      label: element.course_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.courseLoading = false));
          }, 1500);
        }
      }
    },
    getProducts() {
      this.productLoading = true;
      _productService
        .showProductsAll()
        .then((res) => {
          this.products = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.productLoading = false));
    },
    searchCompany(_search) {
      if (_search && _search.length >= 3) {
        if (!this.companiesLoading) {
          this.companiesLoading = true;
          setTimeout(() => {
            _recordMediaService
              .autoCompleteEditionCompany(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.companies = [];
                  res.content.forEach((element) => {
                    this.companies.push({
                      value: element.company_id,
                      label: element.company_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.companiesLoading = false));
          }, 1500);
        }
      }
    },
    searchStatus(_search) {
      if (_search && _search.length >= 3) {
        if (!this.statusLoading) {
          this.statusLoading = true;
          setTimeout(() => {
            _prioritiesService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.editionStatus = [];
                  res.content.forEach((element) => {
                    this.editionStatus.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                } else if ("PENDENTE".includes(_search.toUpperCase())) {
                  this.editionStatus = [];
                  this.editionStatus.push({
                    value: 0,
                    label: "Pendente",
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.statusLoading = false));
          }, 1500);
        }
      }
    },
  },
};
</script>
<style >
.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  max-width: 250px;
  padding: 0.5rem 0rem;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
}
</style>